import React from "react";
import styled from "styled-components";
import Slider from "react-slick"; // Import the Slider
import Image1 from "../../assets/img/Team/team-4.jpg";
import Image2 from "../../assets/img/Team/team-1.jpg";
import Image3 from "../../assets/img/Team/team-2.jpg";
import Image4 from "../../assets/img/Team/team-3.jpg";

// Styled components
const TeamSection = styled.section`
  background-color: #f9f9f9;
  padding: 60px 0;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const SectionTitle = styled.div`
  text-align: center;
  margin-bottom: 40px;

  h2 {
    font-size: 32px;
    color: #333;
    margin-bottom: 20px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    color: #666;
  }
`;

const Member = styled.div`
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: 0.3s;
  padding: 20px;
  margin: 20px; /* Add margin to separate the cards */

  &:hover {
    transform: translateY(-10px);
    .social {
      opacity: 1;
    }
  }
`;

const MemberImg = styled.div`
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const SliderContainer = styled.div`
  .slick-slide {
    padding: 0 10px; /* Adjust padding between slides */
  }
`;

const Social = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.85);
  opacity: 0;
  transition: 0.3s;

  a {
    margin: 0 10px;
    color: #222;
    transition: color 0.3s;

    &:hover {
      color: #3498db;
    }

    i {
      font-size: 18px;
    }
  }
`;

const MemberInfo = styled.div`
  padding: 20px;
`;

const MemberName = styled.h4`
  font-size: 18px;
  color: #333;
  margin-bottom: 5px;
  font-weight: 600;
`;

const MemberTitle = styled.span`
  display: block;
  font-size: 14px;
  color: #666;
`;

const Stars = styled.div`
  margin-bottom: 15px;
`;

const Star = styled.span`
  color: #ffcc00;
  font-size: 20px;
`;

const teamMembers = [
  {
    name: "Pro. Michael Adams",
    title: "Senior Researcher",
    imgSrc: Image4,
    social: [
      { icon: "bi bi-twitter", link: "#" },
      { icon: "bi bi-google-scholar", link: "#" },
      { icon: "bi bi-linkedin", link: "#" },
    ],
    stars: 5,
  },
  {
    name: "Dr. Emily Clark",
    title: "Professor of Literature",
    imgSrc: Image1,
    social: [
      { icon: "bi bi-twitter", link: "#" },
      { icon: "bi bi-google-scholar", link: "#" },
      { icon: "bi bi-linkedin", link: "#" },
    ],
    stars: 5,
  },
  {
    name: "Robert Bennett",
    title: "Data Scientist",
    imgSrc: Image2,
    social: [
      { icon: "bi bi-twitter", link: "#" },
      { icon: "bi bi-researchgate", link: "#" },
      { icon: "bi bi-linkedin", link: "#" },
    ],
    stars: 5,
  },
  {
    name: "Dr. Sophie Lee",
    title: "Historian",
    imgSrc: Image3,
    social: [
      { icon: "bi bi-twitter", link: "#" },
      { icon: "bi bi-google-scholar", link: "#" },
      { icon: "bi bi-linkedin", link: "#" },
    ],
    stars: 5,
  },
];

const Team = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <TeamSection id="team">
      <Container>
        <SectionTitle>
          <h2>Writers</h2>
          <p>
            We provide essential and quality content that stands out for its
            uniqueness and attention to detail. Our team ensures excellence in
            every piece of work.
          </p>
        </SectionTitle>

        <SliderContainer>
          <Slider {...settings}>
            {teamMembers.map((member, index) => (
              <Member key={index}>
                <MemberImg>
                  <img src={member.imgSrc} alt={member.name} />
                  <Social className="social">
                    {member.social.map((link, i) => (
                      <a href={link.link} key={i}>
                        <i className={link.icon}></i>
                      </a>
                    ))}
                  </Social>
                </MemberImg>
                <MemberInfo>
                  <MemberName>{member.name}</MemberName>
                  <MemberTitle>{member.title}</MemberTitle>
                </MemberInfo>
                <Stars>
                  {Array.from({ length: member.stars }).map((_, i) => (
                    <Star key={i}>★</Star>
                  ))}
                </Stars>
              </Member>
            ))}
          </Slider>
        </SliderContainer>
      </Container>
    </TeamSection>
  );
};

export default Team;
