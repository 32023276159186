import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import Client1 from "../../assets/Logos/client1.jpg";
import Client2 from "../../assets/Logos/client2.jpg";
import Client3 from "../../assets/Logos/client3.jpg";
import Client4 from "../../assets/Logos/client4.jpg";
import Client5 from "../../assets/Logos/client5.jpg";

// Styled components for the clients slider section
const ClientsSection = styled.div`
  background-color: #007bff; /* Use a solid color or replace with your image */
  padding: 40px 0; /* Adjust padding for the desired height */
  position: relative;
  text-align: center;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  z-index: 1;
`;

const SliderContainer = styled.div`
  position: relative;
  z-index: 2; /* Ensure the slider appears above the overlay */
`;

const ClientImage = styled.img`
  max-height: 60px; /* Smaller logo size */
  margin: 0 20px;
`;

const Clients = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Show 5 logos at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <ClientsSection>
      <Overlay />
      <SliderContainer>
        <Slider {...sliderSettings}>
          <div>
            <ClientImage src={Client1} alt="Client 1" />
          </div>
          <div>
            <ClientImage src={Client2} alt="Client 2" />
          </div>
          <div>
            <ClientImage src={Client3} alt="Client 3" />
          </div>
          <div>
            <ClientImage src={Client4} alt="Client 4" />
          </div>
          <div>
            <ClientImage src={Client5} alt="Client 5" />
          </div>
        </Slider>
      </SliderContainer>
    </ClientsSection>
  );
};

export default Clients;
