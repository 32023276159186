import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Update import
import styled from "styled-components";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/img/logo/logo.jpeg";
import BurgerIcon from "../../assets/svg/BurgerIcon";

export default function TopNavbar() {
  const [scrollY, setScrollY] = useState(window.scrollY);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={setSidebarOpen} />
      {sidebarOpen && <Backdrop toggleSidebar={setSidebarOpen} />}
      <Wrapper
        className="flexCenter animate whiteBg"
        style={{ height: scrollY > 100 ? "60px" : "80px" }}
      >
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="/">
          <img
    src={LogoIcon}
    alt="Logo"
    style={{ width: '100px', height: '70px', marginRight: '10px' }} // Adjust size and margins as needed
  />
            <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
              Elite Aid Esssays
            </h1>
          </Link>
          <BurgerWrapper className="pointer" onClick={() => setSidebarOpen(!sidebarOpen)}>
            <BurgerIcon />
          </BurgerWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <Link to="/" style={{ padding: "10px 15px" }}>
                Home
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link to="/about" style={{ padding: "10px 15px" }}>
                About
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link to="/service" style={{ padding: "10px 15px" }}>
                Services
              </Link>
            </li>
            {/* <li className="semiBold font15 pointer">
              <Link to="/pricing" style={{ padding: "10px 15px" }}>
                Pricing
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link to="/order" style={{ padding: "10px 15px" }}>
                Order
              </Link>
            </li> */}
          </UlWrapper>
          <UlWrapperRight className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <a href="/" style={{ padding: "10px 30px 10px 0" }}>
                Log in
              </a>
            </li>
            <li className="semiBold font15 pointer flexCenter">
              <Link to="/order" className="radius8 lightBg" style={{ padding: "10px 15px" }}>
                Place Order
              </Link>
            </li>
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;

const NavInner = styled.div`
  position: relative;
  height: 100%;
`;

const BurgerWrapper = styled.button`
  outline: none;
  border: 0;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  
  @media (max-width: 760px) {
    display: block;
  }
`;

const UlWrapper = styled.ul`
  display: flex;
  
  @media (max-width: 760px) {
    display: none;
  }
`;

const UlWrapperRight = styled.ul`
  display: flex;
  
  @media (max-width: 760px) {
    display: none;
  }
`;
