import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";

const AcademicLevelSelector = ({ formData, setFormData }) => {
  const levels = ["High school", "Undergrad. (yrs 1-2)", "Undergrad. (yrs 3-4)", "Master's", "PhD"];

  return (
    
    <Row>
      
      <Col md={12} className="mb-3">
        <Form.Group controlId="academicLevel">
          <Form.Label>Academic level</Form.Label>
          <div className="d-flex">
            {levels.map((level) => (
              <Button
                key={level}
                variant={formData.academicLevel === level ? "primary" : "outline-primary"}
                className="me-2"
                onClick={() =>
                  setFormData((prevData) => ({
                    ...prevData,
                    academicLevel: level
                  }))
                }
              >
                {level}
              </Button>
            ))}
          </div>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default AcademicLevelSelector;
