import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Sections
import TopNavbar from "../Nav/TopNavbar";
import Sidebar from "../Nav/Sidebar";
import Home from "../Sections/Home";
import About from "../Sections/about"
import Price from "../Sections/pricing"
import Service from "../Sections/Service";
import Footer from "../Sections/Footer";
import Order from "../Sections/order";

export default function Landing() {
  return (
    <Router>
      <TopNavbar />
      <Sidebar />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/service" element={<Service />} />
          <Route path="/pricing" element={<Price />} />
          <Route path="/order" element={<Order />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}
