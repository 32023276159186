import React from 'react';
import styled from 'styled-components';
import Image1 from '../../assets/img/hand-drawn-essay-illustration_23-2150268421.jpg'

// Styled Components
const Section = styled.section`
  padding: 60px 0;
  background: #f9f9f9;
`;

const Container = styled.div`
  max-width: 1170px;
  margin: 0 auto;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const Column = styled.div`
  flex: 1;
  padding: 15px;
  max-width: 33.3333%;
  box-sizing: border-box;
  text-align: center;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const SingleFeatures = styled.div`
  text-align: center;
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  h3 {
    font-size: 20px;
    margin-top: 20px;
    color: #023A5B; /* Darker text color */
  }

  p {
    color: #777;
    margin-top: 10px;
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const SingleIcon = styled.div`
  font-size: 48px;
  color: #025B3A; /* Primary icon color */

  &:hover {
    color: #02245B; /* Darker color on hover */
  }
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #f4f4f9;
  margin-bottom: 40px;
`;

const TextWrapper = styled.div`
  flex: 1;
  padding-left: 20px;

  h4 {
    font-size: 24px;
    color: #02505B; /* Use one of the given colors */
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    color: #555;
  }
`;

const StyledImage = styled.img`
  width: 150px;
  height: auto;
  flex-shrink: 0;
`;

// React Component
const FeaturesSection = () => {
  return (
    <Section>
      <Container>
        <Row>
          <SectionTitle>
            <StyledImage src={Image1} alt="Section" />
            <TextWrapper>
              <h4>We Are Dedicated to Enhancing Your Academic Writing</h4>
              <p>
                Our team of professional writers and editors is committed to providing 
                high-quality academic writing assistance to students and researchers. 
                We offer services that are designed to improve clarity, coherence, 
                and academic rigor in your work.
              </p>
            </TextWrapper>
          </SectionTitle>
        </Row>
        <Row>
          <Column>
            <SingleFeatures>
              <SingleIcon>
                <i className="icofont icofont-pen-alt-2"></i>
              </SingleIcon>
              <h3>Custom Essay Writing</h3>
              <p>
                Our experienced writers craft custom essays that meet academic standards, 
                tailored to your specific requirements and guidelines.
              </p>
            </SingleFeatures>
          </Column>
          <Column>
            <SingleFeatures>
              <SingleIcon>
                <i className="icofont icofont-edit-alt"></i>
              </SingleIcon>
              <h3>Editing & Proofreading</h3>
              <p>
                We offer meticulous editing and proofreading services to ensure your 
                academic writing is free from errors and adheres to the highest standards of grammar and style.
              </p>
            </SingleFeatures>
          </Column>
          <Column>
            <SingleFeatures>
              <SingleIcon>
                <i className="icofont icofont-graduate"></i>
              </SingleIcon>
              <h3>Research Paper Assistance</h3>
              <p>
                Whether you need help with research, writing, or formatting, 
                we provide comprehensive support for crafting well-researched and impactful papers.
              </p>
            </SingleFeatures>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default FeaturesSection;
