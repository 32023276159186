import React from "react";
import aboutimg from "../../assets/img/images/about.jpg";

const AboutSection = () => {
  return (
    <section className="about_section layout_padding">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="detail-box">
              <div className="heading_container">
                <h2>About Us</h2>
              </div>
              <p>
                ExpertEssays is a professional essay writing service that sets
                itself apart with a personalized approach and a commitment to
                excellence. The company is backed by a team of native
                English-speaking experts, including writers, editors, and tutors
                with strong academic backgrounds and extensive research
                experience. ExpertEssays emphasizes client-focused service,
                ensuring that each customer receives individual attention and
                support. True to its name, all work at ExpertEssays is
                meticulously crafted by human experts, ensuring originality and
                high quality in every project, from essays to technical
                assignments.
              </p>
              <a href="/">Read More</a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="img-box">
              <img src={aboutimg} alt="About Us" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
