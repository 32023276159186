import React from 'react';
import styled from 'styled-components';
import Image2 from '../../assets/img/41806.jpg';
import Image1 from '../../assets/img/zmly_4ylo_150921.jpg';
import CountUp from 'react-countup';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// New color palette
const colors = {
  background: '#f4f4f9', // Light gray background
  primaryText: '#333333', // Dark gray for text
  secondaryText: '#555555', // Lighter gray for secondary text
  accent: '#4a90e2', // Blue accent
  gold: '#FFD700', // Gold for highlights
  white: '#ffffff',
};

// Container for the entire section
const CounterSection = styled.section`
  background-color: ${colors.background};
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
`;

// Wrapper to center the content and control the width
const CounterWrapper = styled.div`
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
`;

// Each achievement or service item
const ServiceItem = styled.div`
  text-align: center;
  color: ${colors.primaryText};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px;
  height: 100%; /* Ensure the item takes up full height */
`;

// Icon or image styling for service icons
const ServiceIcon = styled.img`
  width: 80px;
  height: auto;
  margin-bottom: 10px;
  display: block; /* Ensures the image is treated as a block element */
  margin-left: auto;
  margin-right: auto; /* Centers the image horizontally */
`;

// Service title or description text
const ServiceTitle = styled.h3`
  font-size: 18px;
  color: ${colors.gold};
  margin-bottom: 5px;
  position: relative;
  z-index: 1;
`;

// Service description text
const ServiceDescription = styled.p`
  font-size: 16px;
  color: ${colors.secondaryText};
`;

// Slider component with settings
export default function CounterSectionComponent() {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Auto slide every 3 seconds
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Adjust for mobile devices
        },
      },
    ],
  };

  return (
    <CounterSection>
      <CounterWrapper>
        <Slider {...sliderSettings}>
          <ServiceItem>
            <ServiceIcon src={Image2} alt="Academic Writing Icon" />
            <ServiceTitle>
              <CountUp end={50000} duration={3} />+ Papers Written
            </ServiceTitle>
            <ServiceDescription>Trusted by students globally</ServiceDescription>
          </ServiceItem>
          <ServiceItem>
            <ServiceIcon src={Image1} alt="Subjects Covered Icon" />
            <ServiceTitle>
              <CountUp end={100} duration={3} />+ Subjects Covered
            </ServiceTitle>
            <ServiceDescription>From literature to engineering</ServiceDescription>
          </ServiceItem>
          <ServiceItem>
            <ServiceIcon src={Image2} alt="Customer Satisfaction Icon" />
            <ServiceTitle>
              <CountUp end={98} duration={3} />% Customer Satisfaction
            </ServiceTitle>
            <ServiceDescription>High-quality papers guaranteed</ServiceDescription>
          </ServiceItem>
          <ServiceItem>
            <ServiceIcon src={Image1} alt="Plagiarism Free Icon" />
            <ServiceTitle>
              <CountUp end={100} duration={3} />% Plagiarism-Free
            </ServiceTitle>
            <ServiceDescription>Original content every time</ServiceDescription>
          </ServiceItem>
          <ServiceItem>
            <ServiceIcon src={Image2} alt="Experienced Writers Icon" />
            <ServiceTitle>
              <CountUp end={500} duration={3} />+ Experienced Writers
            </ServiceTitle>
            <ServiceDescription>Experts in every field</ServiceDescription>
          </ServiceItem>
        </Slider>
      </CounterWrapper>
    </CounterSection>
  );
}
