import React from 'react';
import styled from 'styled-components';
import { FaEye } from 'react-icons/fa';

const UserInfoForm = ({ formData, handleChange }) => {
  return (
    <Container>
      

      <Card>
        
        <FormGroup>
          <Label>Email</Label>
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email"
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Password</Label>
          <InputGroup>
            <Input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Create a password"
              required
            />
            <FaEye style={{ marginLeft: '-30px', cursor: 'pointer' }} />
          </InputGroup>
        </FormGroup>

        <FormGroup>
          <Label>Name</Label>
          <Input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your name"
          />
        </FormGroup>

        <FormGroup>
          <Label>Phone</Label>
          <InputGroup>
            <InputGroupText>+1</InputGroupText>
            <Input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Enter your phone number"
            />
          </InputGroup>
        </FormGroup>

        <CheckboxContainer>
          <Checkbox type="checkbox" id="checkbox1" />
          <CheckboxLabel htmlFor="checkbox1">
            I agree to receive discount coupons, exclusive offers, and the latest news by email, SMS, phone, and other electronic means
          </CheckboxLabel>
        </CheckboxContainer>

        <CheckboxContainer>
          <Checkbox type="checkbox" id="checkbox2" />
          <CheckboxLabel htmlFor="checkbox2">
            I agree to the <a href="#terms">Terms & Conditions</a> and <a href="#privacy">Privacy Policy</a>
          </CheckboxLabel>
        </CheckboxContainer>

        <Button>Create account & Sign in</Button>
      </Card>
    </Container>
  );
};

export default UserInfoForm;

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  padding: 30px;
`;

const Card = styled.div`
  padding: 20px;
  width: 400px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const BannerText = styled.span`
  font-weight: bold;
  color: #007bff;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #333;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 16px;
  &:focus {
    border-color: #80bdff;
    outline: none;
  }
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
`;

const InputGroupText = styled.span`
  padding: 10px;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 5px 0 0 5px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const CheckboxLabel = styled.label`
  font-size: 14px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;
