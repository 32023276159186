import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import TestimonialSlider from "../Elements/TestimonialSlider";
import Writer from "../Elements/writer";
import Counter from"../Elements/counter";
import Clients from "../Elements/ClientSlider";
import Team from "./Team";
import User from "./Users";
import Offers from "../Sections/pricing"
import Courasal from "../Elements/Coursal";

// Create styled components with padding
const Section = styled.section`
  padding: 50px 0;  // You can adjust the padding values as needed
`;

const HeroSection = styled.div`
  padding: 50px 0;
`;

const Home = () => {
  return (
    <>
   
      <HeroSection className="hero_area">
        <Section className="slider_section">
        <Courasal />
        </Section>
      </HeroSection>
      <Clients></Clients>
   
      <Writer />
      <Counter/>
      <Offers />
      <Team />
      <User />
      <TestimonialSlider />
      

     
    </>
  );
};

export default Home;
