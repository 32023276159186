import React from "react";
import { Form, Row, Col } from "react-bootstrap";

const PaperDetailsForm = ({ formData, handleChange }) => {
  return (
    <>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Group controlId="typeOfPaper">
            <Form.Label>Type of paper</Form.Label>
            <Form.Control
              as="select"
              name="typeOfPaper"
              value={formData.typeOfPaper}
              onChange={handleChange}
              required
            >
              <option value="">Select...</option>
              <option value="Essay">Essay</option>
              <option value="Research Paper">Research Paper</option>
              <option value="Term Paper">Term Paper</option>
              <option value="Dissertation">Dissertation</option>
            </Form.Control>
          </Form.Group>
        </Col>

        <Col md={6} className="mb-3">
          <Form.Group controlId="discipline">
            <Form.Label>Discipline</Form.Label>
            <Form.Control
              type="text"
              name="discipline"
              value={formData.discipline}
              onChange={handleChange}
              placeholder="e.g., Business, History"
              required
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={6} className="mb-3">
          <Form.Group controlId="topic">
            <Form.Label>Topic</Form.Label>
            <Form.Control type="text" name="topic" value={formData.topic} onChange={handleChange} />
          </Form.Group>
        </Col>
      </Row>

      <Form.Group controlId="instructions" className="mb-3">
        <Form.Label>Paper instructions</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="instructions"
          value={formData.instructions}
          onChange={handleChange}
          placeholder="Write any important instructions..."
        />
      </Form.Group>
    </>
  );
};

export default PaperDetailsForm;
