import logo from './logo.svg';
import Landing from './Components/screens/Landing'


function App() {
  return (
    <div className="App">
 <Landing></Landing>
    </div>
  );
}

export default App;
