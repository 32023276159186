import React from 'react';

const ServiceSection = () => {
  const services = [
    {
      icon: 'fa-graduation-cap',
      title: 'Assignments',
      description: 'Need top-quality academic writing? Our team offers reliable and affordable assignment help to students in high school, college, and grad school. Ace your essays and exams with our expert support!',
      delay: '0.1s',
    },
    {
      icon: 'fa-book',
      title: 'Thesis',
      description: 'Writing a standout university thesis can be daunting, especially with the added challenge of defending it. Our professional thesis writing service handles the hard part for you. We provide well-researched, custom papers designed to impress even the toughest professors. Let us help you conquer your thesis with ease!',
      delay:'0.3s',
    },
    {
      icon: 'fa-pen-alt',
      title: 'Dissertation',
      description: 'Reaching the final stages of your academic journey is an achievement in itself, but writing a Ph.D. dissertation can be incredibly challenging. Don’t risk stumbling at the finish line. Our Ph.D. dissertation writing service provides expert assistance to ensure your work impresses even the most meticulous professors. Trust us to help you succeed with confidence.',
      delay: '0.5s',
    },
    {
      icon: 'fa-file-alt',
      title: 'Research Paper',
      description: "Writing research papers can be overwhelming, especially with a busy schedule. If you're dreading the task, let us help. Our custom research paper writing service connects you with professional writers who can deliver top-quality work quickly and efficiently. Take the stress off your plate and focus on what matters most.",
      delay: '0.6s',
    },
    {
      icon: 'fa-edit',
      title: 'Essay',
      description: "School life is full of unforgettable moments, but essays can be a burden. Imagine getting through college without having to write another essay, especially in subjects you're not passionate about. With our service, you can submit a well-crafted, expertly-written paper without the hassle of research. Make your academic journey smoother and more enjoyable with our help!",
      delay: '0.7s',
    },
    {
      icon: 'fa-clipboard',
      title: 'Coursework',
      description: "Coursework can be one of the toughest and most time-consuming college tasks, demanding deep knowledge and significant effort. It often plays a crucial role in determining your overall grade. If you're struggling to achieve the results you want despite your hard work, we’re here to help. Our expert service ensures your coursework is flawless and helps you avoid unnecessary stress.",
      delay: '0.9s',
    },
    {
      icon: 'fa-chart-bar',
      title: 'Data Analysis & Statistics',
      description: "Our data and statistics teams are deeply integrated within local contexts, ensuring that the insights we provide are both relevant and actionable. From comprehensive statistical analysis to data visualization, we help you make informed decisions with confidence. Utilizing advanced tools like Tableau and Power BI, we transform complex data into clear, impactful visuals, making it easier for you to understand and leverage your data effectively.",
      delay: '1.1s',
    },
  ];

  const ServiceCard = ({ icon, title, description, delay }) => (
    <div
      className={`col-lg-4 col-sm-6 wow fadeInUp`}
      data-wow-delay={delay}
    >
      <div className="service-item text-left pt-3">
        <div className="p-4">
          {icon && (
            <i className={`fa fa-3x ${icon} text-danger mb-4`} aria-label={title}></i>
          )}
          <h5 className="mb-3">{title}</h5>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-4">
          {services.map((service, index) => (
            <ServiceCard 
              key={index}
              icon={service.icon}
              title={service.title}
              description={service.description}
              delay={service.delay}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
