import React from "react";
import Image1 from "../../assets/img/images/beautiful-brunette-woman-staying-her-workplace.jpg";
import Image2 from "../../assets/img/images/young-woman-reading-documents-with-laptop-table-restaurant_23-2147936145.jpg";
import Image3 from "../../assets/img/images/zmly_4ylo_150921.jpg";

const Courasal = () => {
  return (
    <div
      id="carouselExampleSlidesOnly"
      className="carousel slide"
      data-bs-ride="carousel"
      style={{
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
        height: 550,
        color: "white",
        textAlign: "center",
      }}
    >
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={Image1} className="d-block w-100" alt="..." />
          <div
            className="carousel-caption"
            style={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <h1 style={{ fontWeight: "bold", fontSize: "2.5rem" }}>
              Elevate Your <br />
              Academic Excellence <br />
              with Expert Writing
            </h1>
            <div className="btn-box" style={{ marginTop: "20px" }}>
              <a
                href="/"
                className="btn btn-primary"
                style={{
                  backgroundColor: "#007bff",
                  color: "#fff",
                  borderRadius: "5px",
                  padding: "10px 20px",
                  marginRight: "10px",
                  fontSize: "1rem",
                }}
              >
                Contact Us
              </a>
              <a
                href="/"
                className="btn btn-secondary"
                style={{
                  backgroundColor: "#6c757d",
                  color: "#fff",
                  borderRadius: "5px",
                  padding: "10px 20px",
                  fontSize: "1rem",
                }}
              >
                About Us
              </a>
            </div>
          </div>
        </div>

        <div className="carousel-item">
          <img src={Image2} className="d-block w-100" alt="..." />
          <div className="carousel-caption">
            <h5>Your Text for Slide 2</h5>
            <p>Some description for the second slide.</p>
          </div>
        </div>

        <div className="carousel-item">
          <img src={Image3} className="d-block w-100" alt="..." />
          <div className="carousel-caption">
            <h5>Your Text for Slide 3</h5>
            <p>Some description for the third slide.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courasal;

