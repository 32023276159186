import React, { useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 40px;
  background-color: #f5f5f5;
`;

const FixedCard = styled.div`
  background-color: #025B3A;
  color: #fff;
  padding: 40px;
  border-radius: 8px;
  max-width: 350px;
  margin-right: 20px;
  flex-shrink: 0;
`;

const CardTitle = styled.h3`
  color: #ff7518;
  font-size: 24px;
  margin-bottom: 20px;
`;

const CardText = styled.p`
  font-size: 16px;
  line-height: 1.5;
`;

const SliderCard = styled.div`
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: 250px;
`;

const SliderText = styled.p`
  font-size: 18px;
  color: #666;
  margin-top: 20px;
`;

const SliderTitle = styled.h3`
  font-size: 20px;
  color: #5b0a50;
`;

const CustomSlider = styled(Slider)`
  width: 100%;
`;

const SlideContainer = styled.div`
  padding: 0 10px;
`;

const TeamSection = ({ fixedCardData, sliderCardsData }) => {
    const [sliderSettings] = useState({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,  // Enable autoplay
        autoplaySpeed: 3000,  // Set autoplay speed (in milliseconds)
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
      
  return (
    <Container>
      <FixedCard>
        <CardTitle>{fixedCardData.title}</CardTitle>
        <CardText>{fixedCardData.text}</CardText>
      </FixedCard>

      <CustomSlider {...sliderSettings}>
        {sliderCardsData.map((card, index) => (
          <SlideContainer key={index}>
            <SliderCard>
              <SliderTitle>{card.title}</SliderTitle>
              <SliderText>{card.text}</SliderText>
            </SliderCard>
          </SlideContainer>
        ))}
      </CustomSlider>
    </Container>
  );
};

const User = () => {
  const fixedCardData = {
    title: "WHO USES Elite Essay Aid?",
    text: "Students who need assistance with challenging assignments, tight deadlines, or complex topics are welcome to use our services. Whether you’re struggling to balance school and work, need assistance with challenging subjects, or just want to focus on your passions, we’re here for you.",
  };

  const sliderCardsData = [
    {
      title: "Inadequate Skills",
      text: "For subjects that might be challenging, such as programming, data analytics, or complex topics, we offer expert consultancy to make your learning journey smoother.",
    },
    {
      title: "Short Deadlines",
      text: "If you're faced with short-deadline assignments, homework, or general coursework, we can step in to relieve you of the pressure. Our team is adept at delivering high-quality work within tight timeframes.",
    },
    {
      title: "Complex Research",
      text: "Navigating through complex research topics can be daunting. Our experienced writers help you dissect and understand intricate topics, providing well-researched and clear assignments.",
    },
    {
      title: "Language Barriers",
      text: "For non-native English speakers, academic writing can be challenging. We provide language assistance to ensure your work meets academic standards, without losing your unique voice.",
    },
    {
      title: "Balancing Commitments",
      text: "Balancing academics with personal and professional commitments can be overwhelming. Let us handle the academic pressure while you focus on what matters most to you.",
    },
    {
      title: "Quality Assurance",
      text: "Our team ensures that every assignment is not only completed on time but also meets the highest quality standards. We rigorously check for accuracy, originality, and adherence to guidelines.",
    },
    {
      title: "Tailored Guidance",
      text: "Each student’s needs are unique. Our team offers tailored guidance and support to ensure that your assignments reflect your understanding and meet academic expectations.",
    },
    {
      title: "Confidentiality",
      text: "We understand the importance of privacy. Your information and assignments are handled with the utmost confidentiality, ensuring your academic integrity remains intact.",
    },
  ];
  
  return <TeamSection fixedCardData={fixedCardData} sliderCardsData={sliderCardsData} />;
};

export default User;
