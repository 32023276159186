import React from 'react';
import styled from 'styled-components';
import  Image1  from '../../assets/img/zmly_4ylo_150921.jpg';

// Styled Components
const Section = styled.section`
  padding: 60px 0;
  text-align: center;
  background-color: #f0f4f8;
`;

const Container = styled.div`
  max-width: 1140px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 36px;
  font-weight: bold;
  color: #4d2062; /* Adjust as per design */
  margin-bottom: 40px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const Col = styled.div`
  flex: 0 0 30%;
  max-width: 30%;
  margin-bottom: 40px;
  text-align: center;

  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const IconWrapper = styled.div`
  margin-bottom: 20px;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #e6f7ff;
    padding: 20px;
  }
`;

const StepTitle = styled.h3`
  font-size: 22px;
  color: #333;
  margin-bottom: 10px;
`;

const StepDescription = styled.p`
  font-size: 16px;
  color: #777;
  line-height: 1.6;
`;

const Button = styled.a`
  background-color: #ff6c00;
  color: white;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
  display: inline-block;

  &:hover {
    background-color: #ff8c32;
  }
`;

const HowItWorksSection = () => {
  return (
    <Section>
      <Container>
        <Title>How It Works - Just 3 Steps!</Title>
        <Row>
          <Col>
            <IconWrapper>
              <img src={Image1} alt="Fill in a Brief" />
            </IconWrapper>
            <StepTitle>Fill in a Brief</StepTitle>
            <StepDescription>
              Brief the team by filling in <a href="#">this order form</a> – tell us the number of pages, deadline, attach any relevant files, then check out.
            </StepDescription>
          </Col>
          <Col>
            <IconWrapper>
              <img src={Image1} alt="Order in Progress" />
            </IconWrapper>
            <StepTitle>Order in Progress</StepTitle>
            <StepDescription>
              Our team works on your order and keeps in touch with you via WhatsApp, Email, or your preferred communication channel.
            </StepDescription>
          </Col>
          <Col>
            <IconWrapper>
              <img src={Image1} alt="Get it done in Time" />
            </IconWrapper>
            <StepTitle>Get it done in Time</StepTitle>
            <StepDescription>
              Once done, we will upload the assignment plus Plagiarism and AI reports on the website for you to download & review.
            </StepDescription>
          </Col>
        </Row>
        <Button href="#">Get Started</Button>
      </Container>
    </Section>
  );
};

export default HowItWorksSection;
